import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/components/general/MainLayout.vue'),
        children: [
            {
                path: '',
                sensitive: true,
                name: 'home',
                component: () => import('@/components/general/WorkerDeagrame.vue'),
            },
            {
                path: 'worker/:id',
                name: 'worker',
                component: () => import('@/components/general/WorkerPage.vue'),
            },
            {
                path: '403',
                name: 'AccessDenied',
                meta: {
                    title: 'Access Denied',
                },
                component: () => import('@/views/AccessDenied.vue'),
            },
            {
                path: 'drivers',
                name: 'drivers',
                component: () => import('@/views/pages/Driver/Driver.vue'),
            },
            {
                path: 'driver/manage/:id?',
                name: 'driver-manage',
                component: () => import('@/views/pages/Driver/DriverManage.vue'),
            },
            {
                path: 'users',
                name: 'users',
                component: () => import('@/views/pages/User/User.vue'),
            },
            {
                path: 'user/manage/:id?',
                name: 'user-manage',
                component: () => import('@/views/pages/User/UserManage.vue'),
            },
            {
                path: 'roles',
                name: 'roles',
                component: () => import('@/views/pages/Role/Role.vue'),
            },
            {
                path: 'role/manage/:id?',
                name: 'role-manage',
                component: () => import('@/views/pages/Role/RoleManage.vue'),
            },
            {
                path: 'companies',
                name: 'companies',
                component: () => import('@/views/pages/Company/Company.vue'),
            },
            {
                path: 'company/manage/:id?',
                name: 'company-manage',
                component: () => import('@/views/pages/Company/CompanyManage.vue'),
            },
            {
                path: 'task-state',
                name: 'task-state',
                component: () => import('@/views/pages/Task/TaskState.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    let token = cookies.get('token');

    if (to.name !== 'login' && !token) {
        return next({ name: 'login' })
    } else {
        if (token && to.name === 'login') {
            return next('/')
        } else {
            return next()
        }
    }
});

export default router;